import contactPhoneActions from "actions/contact_phone";
import contactEmailActions from "actions/contact_email";
import contactLocationActions from "actions/contact_location";
import orderActions from "actions/order";
import {
    ButtonLink,
    Notification,
    Person,
    STATUS_TYPES,
    VALIDATORS,
    generateResolver,
    yup,
} from "dyl-components";
import { useContext, useEffect } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, Grid, Header, Segment, Select } from "semantic-ui-react";
import { STATES } from "shared/constants/STATES";
import { PhoneUtil, StringUtils } from "utils";
import LocationUtils from "utils/LocationUtils";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";

const PHONE_TYPE_OPTIONS = ["Home", "Cell", "Work", "Fax", "Other"].map(
    (value) => ({
        key: value,
        value,
        text: value,
    })
);
const EMAIL_TYPE_OPTIONS = ["Work", "Personal", "Other"].map((value) => ({
    key: value,
    value,
    text: value,
}));

const OrderAddress = ({ type, defaultValues, onSave, account_id }) => {
    const {
        control,
        formState: { isValid, isDirty },
        handleSubmit,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            ...(type === "billing"
                ? {
                      save_address: false,
                  }
                : {}),
            ...defaultValues,
        },
        resolver: generateResolver({
            contact_id: yup.mixed().required("This field is required"),
            address: yup.object({
                street: yup
                    .string()
                    .maxlength(100)
                    .required("This field is required"),
                additional_street: yup.string().maxlength(12),
                city: yup
                    .string()
                    .maxlength(60)
                    .required("This field is required"),
                state: yup.string().required("This field is required"),
                zip: VALIDATORS.US_POSTAL_CODE().required(
                    "This field is required"
                ),
            }),
            ...(type === "shipping"
                ? {
                      phone: VALIDATORS.PHONE_NUMBER().required(
                          "This field is required"
                      ),
                      email: VALIDATORS.EMAIL_ADDRESS().required(
                          "This field is required"
                      ),
                      first_name: yup.string().when(`contact_id`, {
                          is: `shipping-contact`,
                          then: () =>
                              VALIDATORS.FIRST_NAME()
                                  .no_whitespace_only()
                                  .required("This field is required"),
                      }),
                      last_name: yup.string().when(`contact_id`, {
                          is: `shipping-contact`,
                          then: () =>
                              VALIDATORS.LAST_NAME()
                                  .no_whitespace_only()
                                  .required("This field is required"),
                      }),
                      suffix: yup.string().when(`contact_id`, {
                          is: `shipping-contact`,
                          then: () => VALIDATORS.SUFFIX(),
                      }),
                  }
                : {}),
        }),
    });

    const { field: customerField } = useController({
        control,
        name: "contact_id",
    });

    const { field: phoneField } = useController({
        control,
        name: "phone",
    });

    const { field: emailField } = useController({
        control,
        name: "email",
    });

    const { field: addressField } = useController({
        control,
        name: "address",
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (customerField.value !== `shipping-contact`) {
            if (type === "shipping") {
                dispatch(
                    contactPhoneActions.readOptions(customerField.value)
                ).then((response) => {
                    const phones = response.data || [];
                    phoneField.onChange({
                        target: {
                            name: phoneField.name,
                            value:
                                phones.find((phone) => phone.main)?.phone ||
                                phones[0]?.phone ||
                                defaultValues.phone,
                        },
                    });
                });
                dispatch(
                    contactEmailActions.readOptions(customerField.value)
                ).then((response) => {
                    const emails = response.data || [];
                    emailField.onChange({
                        target: {
                            name: emailField.name,
                            value:
                                emails.find((email) => email.main)?.email ||
                                emails[0]?.email ||
                                defaultValues.email,
                        },
                    });
                });
            }
            dispatch(
                contactLocationActions.readOptions(customerField.value)
            ).then((response) => {
                const locations = (response.data || []).filter(
                    ({ street, city, state, zip }) =>
                        street && city && state && zip
                );
                const { street, additional_street, city, state, zip } =
                    locations?.find((location) => location.main) ||
                        locations[0] || {
                            additional_street: "",
                            city: "",
                            state: "",
                            street: "",
                            zip: "",
                        };
                addressField.onChange({
                    target: {
                        name: addressField.name,
                        value: {
                            street,
                            additional_street,
                            city,
                            state,
                            zip,
                        },
                    },
                });
            });
        } else {
            phoneField.onChange({
                target: {
                    name: phoneField.name,
                    value: "",
                },
            });
            emailField.onChange({
                target: {
                    name: emailField.name,
                    value: "",
                },
            });
            addressField.onChange({
                target: {
                    name: addressField.name,
                    value: {
                        street: "",
                        additional_street: "",
                        city: "",
                        state: "",
                        zip: "",
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, customerField.value]);

    const order = useSelector((state) => state.order.order);
    const isReadingContactOptions = useSelector(
        (state) => state.account.isReadingContactsForPinning
    );
    const options = useSelector((state) => {
        const { phoneOptions, emailOptions, locationOptions } = {
            phoneOptions: state.contact_phone.phoneOptions,
            emailOptions: state.contact_email.emailOptions,
            locationOptions: state.contact_location.locationOptions,
        };
        return [
            { id: order?.account?.id, name: order?.account?.name, email: "" },
            ...state.account.contactsForPinning.map((contact) => ({
                id: contact.id,
                name: `${contact.first_name} ${contact.last_name}`,
                email: contact.email?.email || "",
            })),
            ...(type === "shipping"
                ? [
                      {
                          id: `shipping-contact`,
                          name: `+ Shipping Contact`,
                          email: "",
                      },
                  ]
                : []),
        ].map((record) => ({
            key: record.id,
            value: record.id,
            text: record.name,
            ...(record.id !== "shipping-contact"
                ? {
                      content: (
                          <Person username={record.name} email={record.email} />
                      ),
                      locations:
                          locationOptions[record.id]?.options
                              ?.filter(
                                  ({ street, city, state, zip }) =>
                                      street && city && state && zip
                              )
                              .map(
                                  ({
                                      street,
                                      additional_street,
                                      city,
                                      state,
                                      zip,
                                      id,
                                  }) => ({
                                      key: id,
                                      value: {
                                          street,
                                          additional_street,
                                          city,
                                          state,
                                          zip,
                                      },
                                      text: `${
                                          additional_street
                                              ? `${additional_street} `
                                              : ""
                                      }
        ${street ? `${street} ` : ""}${city ? `${city}, ` : ""}
        ${state ? `${state} ` : ""}
        ${zip || ""}`,
                                  })
                              ) || [],
                      phones:
                          phoneOptions[record.id]?.options?.map((phone) => ({
                              key: phone.id,
                              value: phone.phone,
                              text: phone.phone,
                              content: PhoneUtil.formatPhoneNumber(phone.phone),
                          })) || [],
                      emails:
                          emailOptions[record.id]?.options?.map((email) => ({
                              key: email.id,
                              value: email.email,
                              text: email.email,
                          })) || [],
                  }
                : {
                      content: <ButtonLink>{record.name}</ButtonLink>,
                      locations: [],
                      phones: [],
                      emails: [],
                  }),
        }));
    });
    const selectedOption = options.find(
        (option) =>
            option.value ===
            (customerField.value !== "shipping-contact"
                ? Number(customerField.value)
                : "shipping-contact")
    );

    const { quoteBuilderConfig } = useContext(QuoteBuilderContext);

    const {
        isReadingPhoneOptions,
        isReadingEmailOptions,
        isReadingLocationOptions,
        isUpdatingAddress,
    } = useSelector((state) => ({
        isReadingPhoneOptions:
            state.contact_phone.phoneOptions[customerField.value]?.isReading,
        isReadingEmailOptions:
            state.contact_email.emailOptions[customerField.value]?.isReading,
        isReadingLocationOptions:
            state.contact_location.locationOptions[customerField.value]
                ?.isReading,
        isUpdatingAddress:
            state.order.orderBeingUpdated ||
            state.contact_location.isCreatingContactLocation,
    }));

    const onUpdateAddress = async (value) => {
        try {
            await dispatch(
                orderActions.updateAddresses(
                    account_id,
                    {
                        [type]:
                            type === "billing"
                                ? {
                                    ...value,
                                    contact_id: Number(value?.contact_id)
                                }
                                : value?.contact_id === `shipping-contact`
                                ? {
                                      location: {
                                          address: value?.address,
                                          email: value?.email,
                                          phone: value?.phone,
                                      },
                                      ship_to: `${value?.first_name}_${
                                          value?.last_name
                                      }${
                                          value?.suffix
                                              ? `_${value.suffix}`
                                              : ""
                                      }`,
                                  }
                                : {
                                      location: {
                                        ...value,
                                        contact_id: Number(value?.contact_id)
                                      },
                                      ship_to: selectedOption?.text,
                                  },
                    },
                    null,
                    `${Number(quoteBuilderConfig?.id)}/location`
                )
            );
            if (value.save_address) {
                if (!Boolean(selectedOption?.locations?.length)) {
                    await dispatch(
                        contactLocationActions.addContactLocation(
                            [value?.address],
                            null,
                            Number(customerField.value)
                        )
                    );
                }
            }
            return onSave({
                ...value,
                contact_id: value?.contact_id !== "shipping-contact" ? Number(value?.contact_id) : "shipping-contact", 
                ship_to: selectedOption?.text,
            });
        } catch (e) {
            console.log(e);
            Notification.alert(
                `Failed to update ${type} address`,
                STATUS_TYPES.ERROR
            );
        }
    };

    return (
        <>
            <Header>{StringUtils.capitalize(type)}</Header>
            <Form
                loading={isUpdatingAddress}
                size="mini"
                style={{
                    width: "50em",
                    maxHeight: "25em",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    marginRight: "1em",
                }}
            >
                {type === "shipping" && (
                    <Form.Select
                        width={8}
                        options={options}
                        label="Customer/Contact Name"
                        required
                        value={customerField.value}
                        onChange={(_, { value }) => {
                            customerField.onChange({
                                target: { name: customerField.name, value },
                            });
                        }}
                        {...(selectedOption
                            ? {
                                  text:
                                      selectedOption.value !==
                                      `shipping-contact`
                                          ? selectedOption.content
                                          : "New Shipping Contact",
                              }
                            : {})}
                        selectOnBlur={false}
                        loading={isReadingContactOptions}
                    />
                )}
                {isReadingEmailOptions ||
                isReadingPhoneOptions ||
                isReadingLocationOptions ? (
                    <Segment basic loading />
                ) : (
                    <Grid>
                        {customerField.value === `shipping-contact` && (
                            <Grid.Row columns={"equal"}>
                                <Grid.Column>
                                    <Controller
                                        name={`first_name`}
                                        control={control}
                                        render={({
                                            field: { name, value, onChange },
                                            fieldState: { error },
                                        }) => (
                                            <Form.Input
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({
                                                        target: { name, value },
                                                    });
                                                }}
                                                required
                                                label="First Name"
                                                error={error?.message}
                                                placeholder="Enter first name"
                                                fluid
                                            />
                                        )}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Controller
                                        name={`last_name`}
                                        control={control}
                                        render={({
                                            field: { name, value, onChange },
                                            fieldState: { error },
                                        }) => (
                                            <Form.Input
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({
                                                        target: { name, value },
                                                    });
                                                }}
                                                required
                                                label="Last Name"
                                                error={error?.message}
                                                placeholder="Enter last name"
                                                fluid
                                            />
                                        )}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Controller
                                        name={`suffix`}
                                        control={control}
                                        render={({
                                            field: { name, value, onChange },
                                            fieldState: { error },
                                        }) => (
                                            <Form.Input
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({
                                                        target: { name, value },
                                                    });
                                                }}
                                                label="Suffix"
                                                placeholder="Suff."
                                                error={error?.message}
                                                fluid
                                                width={12}
                                            />
                                        )}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        {type === "shipping" && (
                            <Grid.Row columns="equal">
                                <Grid.Column>
                                    <Controller
                                        control={control}
                                        name={"phone"}
                                        render={({
                                            field: { name, value, onChange },
                                        }) =>
                                            selectedOption?.phones?.length ? (
                                                <Form.Select
                                                    options={
                                                        selectedOption.phones
                                                    }
                                                    label="Phone"
                                                    onChange={(
                                                        _,
                                                        { value }
                                                    ) => {
                                                        onChange({
                                                            target: {
                                                                name,
                                                                value,
                                                            },
                                                        });
                                                    }}
                                                    value={value}
                                                    required
                                                    {...(value
                                                        ? {
                                                              text: PhoneUtil.formatPhoneNumber(
                                                                  value
                                                              ),
                                                          }
                                                        : {})}
                                                />
                                            ) : (
                                                <Form.Field
                                                    control="div"
                                                    label="Phone"
                                                    required
                                                >
                                                    <Grid columns="equal">
                                                        <Grid.Column width={6}>
                                                            <Controller
                                                                name={`phone_type`}
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    field: {
                                                                        name,
                                                                        value,
                                                                        onChange,
                                                                    },
                                                                }) => (
                                                                    <Select
                                                                        name={
                                                                            name
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                        onChange={(
                                                                            _,
                                                                            {
                                                                                value,
                                                                            }
                                                                        ) => {
                                                                            onChange(
                                                                                {
                                                                                    target: {
                                                                                        name,
                                                                                        value,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                        placeholder="Type"
                                                                        options={
                                                                            PHONE_TYPE_OPTIONS
                                                                        }
                                                                        selectOnBlur={
                                                                            false
                                                                        }
                                                                        fluid
                                                                    />
                                                                )}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Controller
                                                                name={`phone`}
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    field: {
                                                                        name,
                                                                        value,
                                                                        onChange,
                                                                    },
                                                                    fieldState:
                                                                        {
                                                                            error,
                                                                        },
                                                                }) => (
                                                                    <Form.Input
                                                                        name={
                                                                            name
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                        onChange={async (
                                                                            _,
                                                                            {
                                                                                value,
                                                                            }
                                                                        ) => {
                                                                            onChange(
                                                                                {
                                                                                    target: {
                                                                                        name,
                                                                                        value,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                        placeholder="Enter phone"
                                                                        error={
                                                                            error?.message
                                                                        }
                                                                        fluid
                                                                    />
                                                                )}
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                </Form.Field>
                                            )
                                        }
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Controller
                                        control={control}
                                        name={"email"}
                                        render={({
                                            field: { name, value, onChange },
                                        }) =>
                                            selectedOption?.emails?.length ? (
                                                <Form.Select
                                                    options={
                                                        selectedOption.emails
                                                    }
                                                    label="Email"
                                                    onChange={(
                                                        _,
                                                        { value }
                                                    ) => {
                                                        onChange({
                                                            target: {
                                                                name,
                                                                value,
                                                            },
                                                        });
                                                    }}
                                                    value={value}
                                                    required
                                                    selectOnBlur={false}
                                                    loading={
                                                        isReadingEmailOptions
                                                    }
                                                />
                                            ) : (
                                                <Form.Field
                                                    control="div"
                                                    label="Email"
                                                    required
                                                >
                                                    <Grid columns="equal">
                                                        <Grid.Column width={6}>
                                                            <Controller
                                                                name={`email_type`}
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    field: {
                                                                        name,
                                                                        value,
                                                                        onChange,
                                                                    },
                                                                }) => (
                                                                    <Select
                                                                        name={
                                                                            name
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                        onChange={(
                                                                            _,
                                                                            {
                                                                                value,
                                                                            }
                                                                        ) => {
                                                                            onChange(
                                                                                {
                                                                                    target: {
                                                                                        name,
                                                                                        value,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                        placeholder="Type"
                                                                        options={
                                                                            EMAIL_TYPE_OPTIONS
                                                                        }
                                                                        selectOnBlur={
                                                                            false
                                                                        }
                                                                        fluid
                                                                    />
                                                                )}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Controller
                                                                name={`email`}
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    field: {
                                                                        name,
                                                                        value,
                                                                        onChange,
                                                                    },
                                                                    fieldState:
                                                                        {
                                                                            error,
                                                                        },
                                                                }) => (
                                                                    <Form.Input
                                                                        name={
                                                                            name
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                        onChange={async (
                                                                            _,
                                                                            {
                                                                                value,
                                                                            }
                                                                        ) => {
                                                                            onChange(
                                                                                {
                                                                                    target: {
                                                                                        name,
                                                                                        value,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                        placeholder="Enter email"
                                                                        error={
                                                                            error?.message
                                                                        }
                                                                        fluid
                                                                    />
                                                                )}
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                </Form.Field>
                                            )
                                        }
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field
                                    control={"div"}
                                    required
                                    label="Address"
                                >
                                    {selectedOption?.locations?.length ? (
                                        <Controller
                                            control={control}
                                            name={"address"}
                                            render={({
                                                field: {
                                                    name,
                                                    value,
                                                    onChange,
                                                },
                                            }) => (
                                                <Select
                                                    options={
                                                        selectedOption.locations
                                                    }
                                                    loading={
                                                        isReadingLocationOptions
                                                    }
                                                    value={value}
                                                    onChange={(
                                                        _,
                                                        { value }
                                                    ) => {
                                                        onChange({
                                                            target: {
                                                                name,
                                                                value,
                                                            },
                                                        });
                                                    }}
                                                    selectOnBlur={false}
                                                    fluid
                                                />
                                            )}
                                        />
                                    ) : (
                                        <Grid columns="equal">
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Controller
                                                        name={`address.label`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                name,
                                                                onChange,
                                                                value,
                                                            },
                                                        }) => (
                                                            <Form.Select
                                                                name={name}
                                                                value={value}
                                                                onChange={(
                                                                    _,
                                                                    { value }
                                                                ) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder="Select Address Label"
                                                                selectOnBlur={
                                                                    false
                                                                }
                                                                options={LocationUtils.getAddressLabelOptions()}
                                                                fluid
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Controller
                                                        name={`address.street`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                name,
                                                                onChange,
                                                                value,
                                                            },
                                                            fieldState: {
                                                                error,
                                                            },
                                                        }) => (
                                                            <Form.Input
                                                                name={name}
                                                                value={value}
                                                                onChange={(
                                                                    _,
                                                                    { value }
                                                                ) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder="Street Address"
                                                                error={
                                                                    error?.message
                                                                }
                                                                fluid
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Controller
                                                        name={`address.additional_street`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                name,
                                                                onChange,
                                                                value,
                                                            },
                                                            fieldState: {
                                                                error,
                                                            },
                                                        }) => (
                                                            <Form.Input
                                                                name={name}
                                                                value={value}
                                                                onChange={(
                                                                    _,
                                                                    { value }
                                                                ) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder="Apt, unit building, floor #"
                                                                error={
                                                                    error?.message
                                                                }
                                                                fluid
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Controller
                                                        name={`address.city`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                name,
                                                                onChange,
                                                                value,
                                                            },
                                                            fieldState: {
                                                                error,
                                                            },
                                                        }) => (
                                                            <Form.Input
                                                                name={name}
                                                                value={value}
                                                                onChange={(
                                                                    _,
                                                                    { value }
                                                                ) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder="City"
                                                                error={
                                                                    error?.message
                                                                }
                                                                fluid
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Controller
                                                        name={`address.state`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                name,
                                                                onChange,
                                                                value,
                                                            },
                                                            fieldState: {
                                                                error,
                                                            },
                                                        }) => (
                                                            <Form.Select
                                                                name={name}
                                                                value={value}
                                                                onChange={(
                                                                    _,
                                                                    { value }
                                                                ) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder="State"
                                                                search
                                                                options={STATES.map(
                                                                    (
                                                                        state
                                                                    ) => ({
                                                                        ...state,
                                                                        value: state.key,
                                                                    })
                                                                )}
                                                                error={
                                                                    error?.message
                                                                }
                                                                fluid
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Controller
                                                        name={`address.zip`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                name,
                                                                onChange,
                                                                value,
                                                            },
                                                            fieldState: {
                                                                error,
                                                            },
                                                        }) => (
                                                            <Form.Input
                                                                name={name}
                                                                value={value}
                                                                onChange={(
                                                                    _,
                                                                    { value }
                                                                ) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                error={
                                                                    error?.message
                                                                }
                                                                placeholder="Zipcode"
                                                                fluid
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            {type === "billing" &&
                                                !Boolean(
                                                    selectedOption?.locations
                                                        ?.length
                                                ) && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Controller
                                                                control={
                                                                    control
                                                                }
                                                                name={
                                                                    "save_address"
                                                                }
                                                                render={({
                                                                    field: {
                                                                        name,
                                                                        value,
                                                                        onChange,
                                                                    },
                                                                }) => (
                                                                    <Form.Checkbox
                                                                        label={`Save address on account`}
                                                                        checked={
                                                                            value
                                                                        }
                                                                        onChange={(
                                                                            _,
                                                                            {
                                                                                checked,
                                                                            }
                                                                        ) => {
                                                                            onChange(
                                                                                {
                                                                                    target: {
                                                                                        name,
                                                                                        value: checked,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}
                                        </Grid>
                                    )}
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </Form>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Button
                            floated="right"
                            color="primary"
                            disabled={
                                !isValid ||
                                (type !== "billing" && !isDirty) ||
                                isUpdatingAddress
                            }
                            onClick={handleSubmit(onUpdateAddress)}
                            loading={isUpdatingAddress}
                        >
                            Save
                        </Form.Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default OrderAddress;

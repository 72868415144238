import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Divider, Form, Grid, Portal, Segment, Tab, Popup, Icon, Button, Header } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';

import { Footer } from './Footer';
import { FieldToggles } from './FieldToggles';
import { RightHeader } from './RightHeader';
import { AndOrToggle, ButtonLink, Notification, OwnerRuleSettings, STATUS_TYPES, Trigger, generateResolver, yup, VALIDATORS } from 'dyl-components';
import CustomPrompt from 'shared/confirmation/CustomPrompt';

import customFieldsActions from 'actions/custom_fields';
import automationActions from 'actions/automation';
import ConfirmModal from 'shared/confirmation/ConfirmModal';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { useConfirm } from 'shared/confirmation/useConfirm';
import { MathUtils, ObjectUtils, StringUtils } from 'utils';
import TriggerForm from 'shared/forms/Assignments/TriggerForm';
import { ConditionsProvider, ConditionsContext } from './ConditionsProvider';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import leadProviderActions from 'actions/lead_provider';
import campaignActions from 'actions/campaigns';
import { MASTER_SOURCE_OPTIONS } from 'shared/constants/MASTER_SOURCE_OPTIONS';
import { ASSIGNMENTS_DYL_FIELDS_SCHEMA, ASSIGNMENTS_OPERATORS_OPTIONS, ASSIGNMENT_RANGE_OPERATORS, ROUTINGS_DYL_FIELDS_SCHEMA } from 'pages/DataMapping/DYLFieldsConstants';
import { useNavigate, useParams } from 'react-router-dom';
import leadIngestionActions from 'actions/lead_ingestion';
import { RoutingsSection } from './RoutingsSection';
import { SegmentsSection } from './SegmentsSection';
import { FIELD } from 'shared/constants/FIELD_TYPE';

const ASSIGNMENT_LIMIT = 75;
const SEGMENTS_LIMIT = 25

const CreateRule = () => {
    const {
        deleteCallbacks,
        callbacks
    } = useContext(ConditionsContext);
    const TRIGGER_MAX = 1;
    const ROUTING_MAX = 1;

    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();
    const { rule_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(0);
    const [activeInnerTab, setActiveInnerTab] = useState(0);
    const [activeSegment, setActiveSegment] = useState(null);
    const [sourceOptions, setSourceOptions] = useState([]);
    const [fieldOptions, setFieldOptions] = useState([]);
    const [phoneFields, setPhoneFields] = useState([]);
    const [emailFields, setEmailFields] = useState([]);
    const [locationFields, setLocationFields] = useState([]);
    const [compositeFields, setCompositeFields] = useState([]);
    const SETTINGS_OPTIONS = [
        { title: 'Active', description: 'Your assignment rule is currently active.', fieldname: 'active' },
        { title: 'Use Availability', description: 'Do not assign to users who are not available.', fieldname: 'availability' },
        { title: 'Use Distribution Cap', description: 'Do not assign to users who have reached their cap limit.', fieldname: 'cap' },
        { title: 'Selective Fallback', description: 'When all distribution team members become unavailable or capped, override them for this rule.', fieldname: 'fallback' },
    ];
    const [isSaving, setIsSaving] = useState(false);
    const [totalPercent, setTotalPercent] = useState(100);
    const [search, setSearch] = useState('');
    const [disabledModules, setDisabledModules] = useState({
        contact: false,
        lead: false,
        opportunity: false
    })

    const { editedTrigger, campaignList, isReadingCampaignList, isReadingProductInterests, product_interests, isReadingTrigger, automation_fields, assignmentRules } = useSelector(state => {
        return {
            editedTrigger: state.automation.trigger,
            isReadingTrigger: state.automation.isReadingTrigger,
            campaignList: state.campaigns.campaignList,
            isReadingCampaignList: state.campaigns.isReadingCampaignList,
            isReadingProductInterests: state.lead_ingestion.isReadingProductInterests,
            product_interests: state.lead_ingestion.product_interests,
            automation_fields: state.custom_fields.automation_fields,
            assignmentRules: state.automation.triggers,
        }
    });

    const isEditing = !!rule_id

    const { isConfirmed } = useConfirm();

    const onChangeActiveTab = (index) => {
        setActiveTab(index);
    }
    const onChangeActiveInnerTab = (index) => {
        setSearch('')
        setActiveInnerTab(index)
    };

    const defaultValues = {
        pipeline: null,
        stage: null,
        converts_to: null,
        converts_from: null,
        triggerActiveFields: [],
        blocks: [],
        segments: [],
        routings: [],
        rule_name: '',
        rule_description: '',
        active: true,
        availability: true,
        cap: true,
        fallback: false
    }

    const { control, formState: { isValid, isDirty, errors }, setError, trigger, handleSubmit, setValue, getValues, watch, reset } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: generateResolver({
            rule_name: VALIDATORS.TEAM_NAME(),
            rule_description: yup.string().no_excessive_whitespaces().maxlength(256).required('This field is required'),
            segments: ASSIGNMENTS_DYL_FIELDS_SCHEMA,
            routings: ROUTINGS_DYL_FIELDS_SCHEMA,
            triggerActiveFields: yup.array().required(),
            pipeline: yup.mixed().when("converts_to", {
                is: (stage) => stage !== 'contact',
                then: schema => schema.required('This field is required'),
                otherwise: schema => schema.nullable(true),
            }),
            stage: yup.mixed().when("converts_to", {
                is: (stage) => stage !== 'contact',
                then: schema => schema.required('This field is required'),
                otherwise: schema => schema.nullable(true),
            }),
        }),
    });

    const resetTriggers = () => {
        const otherValues = watch();
        reset({
            ...otherValues,
            pipeline: null,
            stage: null,
            converts_to: null,
            converts_from: null,
            triggerActiveFields: []
        });
    }

    const [ filteredTriggers, converts_from, module, pipeline ] = watch(['triggerActiveFields', 'converts_from', 'converts_to', 'pipeline']);
    const isActiveTriggers = filteredTriggers?.length >= TRIGGER_MAX;
    
    const onChangeTrigger = (trigger) => {
        switch (trigger?.value) {
            case "new":
                return [<></>]
            case "existing":
                return [<></>]
            case "converts":
                return [
                    <TriggerForm 
                        control={control}
                        converts_from={converts_from}
                        module={module}
                        pipeline={pipeline}
                        reset={reset}
                        getValues={getValues}
                        disabledModules={disabledModules}
                        isEditing={isEditing}
                    />
                ]
            default:
                return []
        }
    }

    CustomPrompt(null, (isDirty) && !isSaving, isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');

    const { fields: blocks, append: addBlock } = useFieldArray({
        control: control,
        name: "blocks"
    });

    const { fields: segments, append: addSegment, move: moveSegments, remove: removeSegment, insert: insertSegment } = useFieldArray({
        control: control,
        name: "segments",
    });

    const { fields: routings, append: addRoutings, update: updateRoutings, remove: removeRouting } = useFieldArray({
        control: control,
        name: "routings"
    });

    const generateSegmentId = () => {
        return 'id_' + Math.random().toString(36).substring(2, 9) + '_' + Date.now();
    }

    const hasRoutings = routings.length > 0;

    const watchedWeighted = watch("routings[0].weighted")
    const isTotalPercentValid = !watchedWeighted || totalPercent === 100;

    const watchedSegments = watch("segments");
 
    const onAddSegment = () => {
        const hasNoBlocks = blocks.length === 0;
        const segmentId = generateSegmentId();
        // TODO: handle case for adding multiple blocks
        if (hasNoBlocks) {
            addBlock({
                boolean_operator: "or"
            }, {});
        }
        addSegment({
            block: hasNoBlocks ? 0 : blocks.length - 1,
            boolean_operator: "and",
            conditions: [],
            segmentId
        })
        setActiveSegment(segments.length);
        onChangeActiveInnerTab(1);
        trigger("segments");
    }

    const getInitialCompositeCondition = (key) => {
        switch (key) {
            case "composite_phone": {
                return phoneFields?.[0] || null;
            }
            case "composite_email": {
                return emailFields?.[0] || null;
            }
            case "composite_location": {
                return locationFields?.[0] || null;
            }
            default: {
                return null
            }
        }
    }

    const getAppend = (index) => {
        const activeSegmentId = watchedSegments[index].segmentId;
        return callbacks?.find((callback) => callback.segmentId === activeSegmentId)?.append;
    }

    const addInitialCompositeCondition = (parentKey) => {
        const append = getAppend(activeSegment);
        const {field_type: type, key, text, options} = getInitialCompositeCondition(parentKey);
        const condition = {
            field: key,
            text,
            type,
            operator: !!ASSIGNMENTS_OPERATORS_OPTIONS[type]? ASSIGNMENTS_OPERATORS_OPTIONS[type][0].key : "",
            options,
            parent: parentKey,
            value: ""
        }
        append(condition);
    }

    const onAddCondition = (field, segment = activeSegment) => {
        const { key, text, options, parent, field_type: type } = field;
        const append = getAppend(segment);
        const isCompositeElement = (field.parent === "Phone" || field.parent === "Location" || field.parent === "Email") && (type !== "composite");
        const operator = !!ASSIGNMENTS_OPERATORS_OPTIONS[type]? ASSIGNMENTS_OPERATORS_OPTIONS[type][0].key : "";
        const isDependency = type === FIELD.DEPENDENCY;
        const conditionsAux =  {
            field: key,
            ...(key === "criteria" ? {
                campaign_converted_id: null,
                data_provider_id: null,
                data_record_id: null,
                master_source: "",
                product_interests: [],
                secondary_source_id: null,
                value: null
            } : {
                text,
                options,
                type,
                ...(type !== "composite" && {
                    operator,
                    value: isDependency ? null : ""
                })
            }),
            ...(isCompositeElement && {
                parent: `composite_${parent.toLowerCase()}`
            }),
        }
        append(conditionsAux);

        if (type === "composite") {
            addInitialCompositeCondition(key);
        }
    }

    const onAddAdditionalField = (parent, segment = activeSegment) => {
        const conditions = watchedSegments?.[segment]?.conditions
        const compositeConditions = conditions.filter((condition) => condition.parent === parent);
        let fields = [];
        switch (parent) {
            case "composite_phone":
                fields = phoneFields || [];
                break;
            case "composite_email":
                fields = emailFields || [];
                break;
            case "composite_location":
                fields = locationFields || [];
                break;
            default:
                break;
        }
        const availableFields = fields.filter((field) => !compositeConditions.find((condition) => condition.field === field.key))
        const firstField = availableFields?.[0] || null;
        onAddCondition(firstField, segment)
    }

    const duplicateSegment = (index) => {
        const segmentId = generateSegmentId();
        const duplicatedConditions = watchedSegments?.[index]?.conditions;
        const segmentCopy = {...segments[index], segmentId, conditions: duplicatedConditions};
        delete segmentCopy.id
        insertSegment(index + 1, segmentCopy);
    }

    const onRemoveSegment = (index) => {
        const removedSegmentId = watchedSegments?.[index]?.segmentId;
        removeSegment(index);
        deleteCallbacks(removedSegmentId);
        if (segments.length === 1) {
            onChangeActiveInnerTab(0);
        }
    }

    const onAddRouting = () => {
        const hasNoBlocks = blocks.length === 0;
        if (hasNoBlocks) {
            addBlock({
                boolean_operator: "or"
            }, {});
        }
        addRoutings({
            block: hasNoBlocks ? 0 : blocks.length - 1,
            action_type: "assignment",
            weighted: false,
            users: [],
            period: '',
            name: '',
            routingActiveFields: []
        })
        onChangeActiveInnerTab(2);
    }

    const onUpdateRoutingActiveFields = (field) => {
        const routing = !!routings[0] ? {...routings[0], routingActiveFields: [...routings[0].routingActiveFields, {...field}]} : null;
        updateRoutings(0, routing);
    }

    const resetRouting = () => {
        updateRoutings(0, {
            block: blocks.length - 1,
            action_type: "assignment",
            distribution_method: "eq",
            users: [],
            period: '',
            name: '',
            routingActiveFields: []
        })
    }

    const getRemove = (index) => {
        const activeSegmentId = watchedSegments[index].segmentId;
        return callbacks?.find((callback) => callback.segmentId === activeSegmentId)?.remove;
    }

    const onRemoveCondition = (field) => {
        const { key, field_type } = field;
        const remove = getRemove(activeSegment)
        const conditions = watchedSegments?.[activeSegment]?.conditions || [];
        let indexesToRemove = [];
        if (field_type === "composite") {
            const conditionsToRemove = conditions?.map(({parent, field}, index) => ({parent, field, index})).filter(({parent}) => parent === key);
            indexesToRemove = conditionsToRemove?.map(({index}) => index);
        }
        indexesToRemove.push(conditions.findIndex((condition) => condition.field === key));
        remove(indexesToRemove);
    }

    const onRemoveAdditionalField = (index, segment) => {
        const remove = getRemove(segment);
        remove(index);
    }

    const findField = (field) => {
        const values = Object.values(compositeFields);
        const completeArray = []
        values.forEach(value => {
            completeArray.push(...value)
        });
        return completeArray.find((compositeField) => field === compositeField.key);
    }

    const onChangeCompositeField = (previousField, field) => {
        const completeFieldToRemove = findField(previousField);
        const completeField = findField(field);
        onRemoveCondition(completeFieldToRemove);
        onAddCondition(completeField);
    }

    const onClearCriteria = (parentName) => {
        setValue(`${parentName}.master_source`, "");
        setValue(`${parentName}.secondary_source_id`, null);
        setValue(`${parentName}.campaign_converted_id`, null);
        setValue(`${parentName}.data_provider_id`, null);
        setValue(`${parentName}.data_record_id`, null);
        setValue(`${parentName}.product_interests`, []);
    }

    const addCriteria = (restriction) => {
        const conditions = [...restriction.conditions];
        const criteria = conditions.find((condition) => condition.field === "criteria");
        if (!!criteria) {
            const criteriaIndex = conditions.findIndex((condition) => condition.field === "criteria");
            conditions.splice(criteriaIndex, 1);
            restriction.conditions = [...conditions];
            restriction.criteria = criteria
        }
    }

    const formatPayload = (data) => {
        const { rule_description: description, rule_name: name, blocks, routings, segments, active, availability, cap, fallback } = data;
        const blocksCopy = [...blocks];
        for (let index = 0; index < segments.length; index++) {
            const restriction = {...segments[index]};
            const conditionsCopy = [...restriction.conditions].map((condition) => {
                if (condition.type === "dependency") {
                    const {parent_value: parent, child_value: child} = condition;
                    return {
                        ...condition,
                        value: {
                            parent,
                            child
                        }
                    }
                }
                if (ASSIGNMENT_RANGE_OPERATORS.includes(condition.operator)) {
                    return {
                        condition,
                        value: [condition.value, `${condition.maxValue}`]
                    }
                }
                return condition;
            });
            restriction.conditions = conditionsCopy.filter(({type}) => type !== "composite")
            addCriteria(restriction)
            const restrictionBlock = restriction.block;
            const restrictionsCopy = blocksCopy[restrictionBlock].restrictions || [];
            blocksCopy[restrictionBlock] = {...blocksCopy[restrictionBlock], restrictions: [...restrictionsCopy, restriction] || []}
        }
        for (let index = 0; index < routings.length; index++) {
            const action = {
                action_type: routings[index].action_type,
                assignment: {
                    ...routings[index],
                    ...(isEditing ? { id: editedTrigger?.segments?.[0].actions?.[0].assignment.id } : {}),
                    distribution_method: "roundrobin",
                },
                block: routings[index].block,
            }
            const actionBlock = action.block;
            const actionsCopy = blocksCopy[actionBlock].action || [];
            blocksCopy[actionBlock] = {...blocksCopy[actionBlock], actions: [...actionsCopy, action]}
        }
        const [ converts_from, pipeline_stage_id, converts_to, pipeline_category_id  ] = getValues(["converts_from", "stage", "converts_to", "pipeline"]);
        let payload = {
            name,
            module: converts_from,
            description,
            type: "converted",
            trigger_data: {
                converts_from, 
                converts_to, 
                pipeline_category_id, 
                pipeline_stage_id
            },
            segments: blocksCopy,
            active,
            availability,
            cap,
            fallback
        }
        return payload;
    }

    const onSave = async (data) => {
        try {
            const payload = formatPayload(data);
            if (!isEditing) {
                await dispatch(automationActions.createTrigger(payload));
            } else {
                await dispatch(automationActions.updateTrigger(rule_id, payload));
            }
            setIsSaving(true);
            Notification.alert(`Assignment Rule successfully ${!isEditing ? "saved" : "edited"}`, STATUS_TYPES.SUCCESS);
            navigate("/settings/assignment-rules/assignment-rules");
        } catch (e) {
            Notification.alert(`Failed to ${!isEditing ? "save" : "edit"} Assignment Rule`, STATUS_TYPES.ERROR);
            console.log(e)
        }
    }

    const onDragEnd = (result) => {
        const {destination, source} = result;
        if (!!destination && !!source) {
            moveSegments(source.index, destination.index);
        }
    };

    const clearAllCriteriaCampaign = () => {
        for (let index = 0; index < segments.length; index++) {
            const criteriaIndex = watchedSegments?.[index]?.conditions?.findIndex((condition) => condition.field === "criteria")
            if (criteriaIndex !== -1) {
                setValue(`segments[${index}].conditions[${criteriaIndex}].campaign_converted_id`, null);
            }
        }
    }

    const onCheckUniqueAssignmentName = async () => {
        const { rule_name } = getValues();
        try {
            const { name } = editedTrigger || {};
            if (!isEditing || rule_name !== name) {
                const isUnique = await dispatch(automationActions.uniqueRuleName({ name: rule_name }));
                if(!isUnique){
                    setError("rule_name", { type: "unique", message: "Name already exists!" })
                } 
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getSegments = (segments) => {
        if (!!segments && !!segments[0]) {
            const { restrictions } = segments[0];
            if (!!restrictions) {
                return restrictions.map((restriction) => {
                    const hasComposite = {
                        email: false,
                        location: false,
                        phone: false,
                    }
                    const { criteria } = restriction;
                    const criteriaCondition = criteria ? {
                        field: "criteria",
                        campaign_converted_id: criteria?.campaign_converted_id || null,
                        data_provider_id: criteria?.data_provider_id || null,
                        data_record_id: criteria?.data_record_id || null,
                        master_source: criteria?.master_source || "",
                        product_interests: criteria?.product_interests || [],
                        secondary_source_id: criteria?.secondary_source_id || null,
                        value: null
                    } : null
                    const segmentId = generateSegmentId();
                    const conditions = restriction?.conditions.map((condition) => {
                        const { field, operator, value } = condition;
                        let completeField = fieldOptions.find((fieldOption) => fieldOption.key === field);
                        if (!!completeField) {
                            const { key, text, field_type, options } = completeField;
                            return {
                                field: key,
                                text: text,
                                type: field_type,
                                operator: operator,
                                options: options,
                                ...(field_type === "dependency" ? {
                                    child_value: !!value.child ? Number(value.child) : null,
                                    parent_value: Number(value.parent)
                                } : {
                                    value: field_type === "dropdown" ||
                                    field_type === "tagsinput" ||
                                    field_type === "rating" ? Number(value) : value,
                                })
                            }
                        }
                        completeField = findField(field);
                        hasComposite[completeField?.parent?.toLowerCase()] = !!completeField?.parent
                        if (!!completeField) {
                            return {
                                field: field,
                                text: completeField.text,
                                type: completeField.field_type,
                                operator: operator,
                                options: completeField.options,
                                parent: `composite_${completeField.parent.toLowerCase()}`,
                                value: completeField.field_type === "dropdown" ? Number(value) : value
                            }
                        }
                        return {}
                    });
                    for (const [key, value] of Object.entries(hasComposite)) {
                        if (value) {
                            const compositeName = `composite_${key}`
                            const index = conditions.findIndex((condition) => condition.parent === compositeName);
                            conditions.splice(index, 0, {
                                field: compositeName,
                                text: StringUtils.capitalize(key),
                                options: false,
                                type: "composite",
                            })
                        }
                    }
                    if (!!criteriaCondition) {
                        conditions.push(criteriaCondition);
                    }
                    return {
                        block: 0,
                        boolean_operator: restriction.boolean_operator,
                        conditions,
                        segmentId
                    }
                })
            }
        }
        return [];
    }

    const getRouting = (segments) => {
        if (!!segments && !!segments[0]) {
            const { actions } = segments[0];
            if (!!actions && !!actions[0]) {
                const { assignment } = actions[0]
                if (!!assignment) {
                    return [{
                        block: 0,
                        action_type: "assignment",
                        weighted: !!assignment.weighted,
                        users: assignment.users,
                        name: assignment.name,
                        routingActiveFields: [{
                            value: "roundrobin",
                            text: "Round Robin",
                            field: "routing",
                            disabled: false
                        }],
                        ...(assignment.period && {period: assignment.period})
                    }]
                }
            }
        }
        return [];
    }

    const loadRule = () => {
        const { type, name, description, segments, fallback, active, cap, availability, trigger_data: { converts_from, converts_to, pipeline_category_id, pipeline_stage_id }} = editedTrigger
        const { boolean_operator } = segments?.[0] || {}

        const segmentsBeingEdited = getSegments(segments);
        if (segmentsBeingEdited.length > 0) {
            setActiveSegment(0);
        }

        const routings = getRouting(segments);

        reset({
            converts_to,
            converts_from,
            routings, 
            pipeline: pipeline_category_id,
            stage: pipeline_stage_id,
            triggerActiveFields: type === "converted" ? [{field: "trigger", text: "Converts", value: "converts"}] : [],
            blocks: segments?.[0] ? [{boolean_operator}] : [],
            segments: segmentsBeingEdited,
            rule_name: name,
            rule_description: description,
            active,
            availability: !!availability,
            cap: !!cap,
            fallback: !!fallback
        })
    }

    useEffect(() => {
        const getSourceOptions = async () => {
            const { data: secondary_sources } = await (async () => {
                try {
                    return await dispatch(leadProviderActions.readSecondarySourceOptions({ limit: 100 }))
                } catch (e) {
                    return { data: [] };
                }
            })();
            return MASTER_SOURCE_OPTIONS.map((master_source => ({
                key: master_source.key,
                value: master_source.value,
                text: master_source.text,
                options: (
                    secondary_sources.filter(secondary_source => secondary_source.master_source === master_source.value).map(secondary_source => ({
                        key: secondary_source.id,
                        value: secondary_source.id,
                        text: secondary_source.name
                    }))
                )
            })))
        }
        if (!!module || !isEditing) {
            getSourceOptions().then((options) => {
                setSourceOptions(options)
            })
            dispatch(customFieldsActions.readAutomationFields({ module: module === null || module === 'contact' ? 'person' : module }))
            dispatch(campaignActions.readCampaignsList({campaign_conversion: module}));
            dispatch(leadIngestionActions.readProductInterests());
            dispatch(automationActions.getTriggers({limit: ASSIGNMENT_LIMIT}));
            clearAllCriteriaCampaign();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, module]);

    useEffect(() => {
        if (assignmentRules) {
            const { contact, lead, opportunity } = assignmentRules
            const disabledModulesAux = {
                contact: contact?.count === 25,
                lead: lead?.count === 25,
                opportunity: opportunity?.count === 25
            }
            setDisabledModules(disabledModulesAux);
        }
    }, [assignmentRules])

    useEffect(() => {
        if (!!automation_fields) {
            const { fieldOptions, compositeFields } = ObjectUtils.extractCompositeFields(ObjectUtils.formatFields(automation_fields));
            const { phoneFields, emailFields, locationFields } = compositeFields
            setFieldOptions(fieldOptions);
            setCompositeFields(compositeFields);
            setPhoneFields(phoneFields);
            setEmailFields(emailFields);
            setLocationFields(locationFields);
        }
    }, [automation_fields])

    useEffect(() => {
        if (isEditing) {
            dispatch(automationActions.getTrigger(rule_id));
        } else {
            reset(defaultValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isEditing, rule_id, reset, ])

    useEffect(() => {
        if (!!editedTrigger && isEditing ) {
            loadRule();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedTrigger, reset, fieldOptions])

    const currentWatchedConditions = watchedSegments?.[activeSegment]?.conditions

    useEffect(() => {
        const residualIndex = watchedSegments?.[activeSegment]?.conditions?.findIndex((condition) => !condition.field)
        if (!!residualIndex && residualIndex !== -1) {
            const remove = getRemove(activeSegment)
            remove(residualIndex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWatchedConditions, activeSegment, watchedSegments])

    useEffect(() => {
        setValue("segments", []);
        setActiveInnerTab(0);
    }, [converts_from, module, setValue])

    return (
        <React.Fragment>
            <ConfirmModal />
            <Grid className='Assignment__Rules' columns='equal'>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <div style={{overflowY: "auto"}}>
                            <Segment textAlign='center' className='Assignment__Rules__controls'>
                                <Tab
                                    onTabChange={(_, { activeIndex }) => { onChangeActiveTab(activeIndex); }}
                                    menu={{ size: 'small', className: 'centered Assignment__Rules__controls-menu', secondary: true, pointing: true, color: 'primary' }}
                                    panes={[
                                        {
                                            menuItem: 'Assignment Rules',
                                            render: () => (
                                                <React.Fragment>
                                                    <Divider hidden />
                                                    <FieldToggles
                                                        control={control}
                                                        segmentControl={control}
                                                        fieldOptions={fieldOptions}
                                                        filteredTriggers={filteredTriggers}
                                                        resetTriggers={resetTriggers}
                                                        isActiveTriggers={isActiveTriggers}
                                                        hasSegments={segments.length > 0}
                                                        trigger={trigger}
                                                        onChangeActiveInnerTab={onChangeActiveInnerTab}
                                                        activeInnerTab={activeInnerTab}
                                                        onAddCondition={onAddCondition}
                                                        onRemoveCondition={onRemoveCondition}
                                                        updateRoutings={onUpdateRoutingActiveFields}
                                                        activeSegment={activeSegment}
                                                        hasRoutings={hasRoutings}
                                                        resetRouting={resetRouting}
                                                        watch={watch}
                                                        watchedSegments={watchedSegments}
                                                        search={search}
                                                        setSearch={setSearch}
                                                    />
                                                </React.Fragment>
                                            )
                                        },
                                        {
                                            menuItem: 'Settings',
                                            render:() => (
                                                <div style={{padding: "20px 30px", textAlign: "left"}}>
                                                    <div className='AssignmentRules__settingsLeft'>
                                                        <div>
                                                            <Icon className='fa-solid fa-file-lines AssignmentRules__settingsIcon' />
                                                        </div>
                                                        <div style={{marginLeft: 5}}>
                                                            <Header as="h4" className='AssignmentRules__settingsIcon'>Rule Settings</Header>
                                                            <span className='AssignmentRules__settingsIcon'>Manage rule and distribution team settings.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    ]}
                                    activeIndex={activeTab}
                                />
                            </Segment>
                        </div>
                    </Grid.Column>
                    <Grid.Column className='AssignmentRules__ruleColumn'>
                        <Form loading={isReadingTrigger}>
                            <Segment basic className='Assignment__Rules__rightPanel'>
                                <div {...(activeTab !== 0 ? { style: { display: 'none' } } : {})}>
                                    <RightHeader
                                        control={control}
                                        onCheckUniqueAssignmentName={onCheckUniqueAssignmentName}
                                    />
                                    <Trigger
                                        triggers={onChangeTrigger(filteredTriggers?.[0])}
                                        onToggle={resetTriggers}
                                        isActive={segments.length < 1}
                                        hasDisabledModules={disabledModules.contact || disabledModules.lead || disabledModules.opportunity}
                                    />
                                    {blocks.map((block, blockIndex) => (
                                        <DragDropContext key={block.id} onDragEnd={onDragEnd}>
                                            <Droppable droppableId={`droppable-${blockIndex}`} type='BLOCK'>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                            {segments.filter(segment => segment.block === blockIndex).map((segment, segmentIndex) => {
                                                                const { segmentId } = segment
                                                                const criteriaIndex = watchedSegments?.[segmentIndex]?.conditions?.findIndex((condition) => condition.field === "criteria")
                                                                const parentName = criteriaIndex !== -1 ? `segments[${segmentIndex}].conditions[${criteriaIndex}]` : "";
                                                                return <>
                                                                    <Draggable draggableId={`draggable-${segmentIndex}`} index={segmentIndex} >
                                                                        {(provided) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                
                                                                            >
                                                                                <Icon 
                                                                                    className='fas fa-arrows-alt AssignmentRules__dndIcon' 
                                                                                    size='big' 
                                                                                    {...provided.dragHandleProps}
                                                                                />
                                                                                <SegmentsSection
                                                                                    key={segment.id}
                                                                                    control={control}
                                                                                    parentName={`segments[${segmentIndex}]`}
                                                                                    segmentIndex={segmentIndex}
                                                                                    segmentId={segmentId}
                                                                                    trigger={trigger}
                                                                                    compositeFields={compositeFields}
                                                                                    watchedSegments={watchedSegments}
                                                                                    onChangeCompositeField={onChangeCompositeField}
                                                                                    onAddAdditionalField={onAddAdditionalField} 
                                                                                    sourceOptions={sourceOptions}
                                                                                    criteriaIndex={criteriaIndex}
                                                                                    onClearCriteria={() => onClearCriteria(parentName)}
                                                                                    onRemoveCriteria={() => onRemoveCondition({key: "criteria"})}
                                                                                    isOnly={watchedSegments?.[segmentIndex]?.conditions?.length > 1}
                                                                                    isReadingCampaignList={isReadingCampaignList}
                                                                                    campaignList={campaignList}
                                                                                    isReadingProductInterests={isReadingProductInterests}
                                                                                    product_interests={product_interests}
                                                                                    setActiveSegment={setActiveSegment}
                                                                                    activeSegment={activeSegment}
                                                                                    onRemoveAdditionalField={onRemoveAdditionalField}
                                                                                    setValue={setValue}
                                                                                    watch={watch}
                                                                                />
                                                                                {activeSegment === segmentIndex && (
                                                                                    <div>
                                                                                        <ButtonLink onClick={() => duplicateSegment(segmentIndex)} status='primary'>
                                                                                            <Icon link size='large' className='fa-solid fa-clone' /> Duplicate
                                                                                        </ButtonLink>
                                                                                        <ButtonLink onClick={() => onRemoveSegment(segmentIndex)} status='error'>
                                                                                            <Icon link size='large'  className='fas fa-circle-minus' /> Remove
                                                                                        </ButtonLink>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                    {provided.placeholder}
                                                                    {segmentIndex < segments.length -1 && (
                                                                        <div style={{marginBottom: 30, marginTop: 30}}>
                                                                            <Controller
                                                                                control={control}
                                                                                name={`blocks[${blockIndex}].boolean_operator`}
                                                                                render={({ field: { onChange, value, name } }) =>
                                                                                    <AndOrToggle
                                                                                        name={name}
                                                                                        options={[
                                                                                            { key: 'and', text: 'And', value: 'and'},
                                                                                            { key: 'or', text: 'Or', value: 'or' },
                                                                                        ]}
                                                                                        value={value}
                                                                                        onChange={(value) => {
                                                                                            onChange({ target: { name, value } })
                                                                                        }}
                                                                                    />
                                                                                } 
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </>
                                                            })}
                                                        {routings.filter((routing) => routing.block === blockIndex).map((routing, routingIndex) => (
                                                            <RoutingsSection
                                                                key={routingIndex}
                                                                routing={routing}
                                                                control={control}
                                                                trigger={trigger}
                                                                getValues={getValues}
                                                                setError={setError}
                                                                routingIndex={routingIndex}
                                                                updateRoutings={updateRoutings}
                                                                removeRouting={removeRouting}
                                                                onChangeActiveInnerTab={onChangeActiveInnerTab}
                                                                segments={segments}
                                                                watch={watch}
                                                                errors={errors}
                                                                totalPercent={totalPercent}
                                                                setTotalPercent={setTotalPercent}
                                                                isEditing={isEditing}
                                                                editedTeamName={editedTrigger?.segments?.[0].actions?.[0]?.assignment?.name}
                                                            />
                                                        ))}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    ))}
                                    <Divider horizontal style={{ paddingBottom: '5em' }}>
                                        <Popup
                                            style={{ padding: 0 }}
                                            trigger={<Icon size="big" color='primary' className="fas fa-plus-circle" link disabled={!isActiveTriggers} />}
                                            content={(
                                                <Button.Group icon basic>
                                                    <Button disabled={segments.length >= SEGMENTS_LIMIT} onClick={() => { onAddSegment() }}>
                                                        Add Segment
                                                    </Button>
                                                    <Button disabled={routings.length >= ROUTING_MAX} onClick={() => { onAddRouting() }}>
                                                        Add Routing
                                                    </Button>
                                                </Button.Group>
                                            )}
                                            position="bottom center"
                                            hoverable
                                        />
                                    </Divider>
                                </div>
                                <div {...(activeTab !== 1 ? { style: { display: 'none' } } : {})}>
                                    <Header style={{marginBottom: 0}}>Rule Settings</Header>
                                    <p style={{marginBottom: 25}}>Select options regarding your routing and distribution team.</p>
                                    <OwnerRuleSettings options={SETTINGS_OPTIONS} control={control} />
                                </div>
                            </Segment>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider hidden />
            <Divider hidden />
            <Portal open>
                <Footer
                    isSaveDisabled={ !isDirty || !isValid  || !hasRoutings || !isTotalPercentValid || isReadingCampaignList || (disabledModules.contact && disabledModules.lead && disabledModules.opportunity) }
                    onSave={handleSubmit(onSave)}
                    width={MathUtils.calculatePercentage(windowWidth, windowWidth - width)}
                />
            </Portal>
        </React.Fragment>
    )
}

const CreateRuleContainer = () => {
    return <ConditionsProvider>
        <CreateRule />
    </ConditionsProvider>
}

export default CreateRuleContainer;

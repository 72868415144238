import orderActions from "actions/order";
import quoteActions from "actions/quote";
import { Modal, Notification, STATUS_TYPES } from "dyl-components";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "semantic-ui-react";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import CatalogWizard from "shared/forms/CatalogWizard";
import { formatCartItems } from "shared/schemas/cart/cartSchema";

const AddProducts = ({ account_id, opportunity_id, contact_id }) => {
    const dispatch = useDispatch();

    const { user_id, user_email, user_phone } = useSelector((state) => ({
        user_id: state.auth.user_id,
        user_email: state.users.userProfile?.email,
        user_phone: state.users.userProfile?.cell_phone,
    }));

    const { quoteBuilderConfig, onEditQuote, onViewOrder } =
        useContext(QuoteBuilderContext);
    const { id: idBeingUpdated, isUpsell } = quoteBuilderConfig;

    const onCreateOrder = async (data) => {
        try {
            const order_id = await dispatch(
                orderActions.create(
                    {
                        items: formatCartItems(data).items,
                    },
                    {
                        quote_id: idBeingUpdated,
                        account_id,
                    }
                )
            );
            Notification.alert(
                "Successfully created order",
                STATUS_TYPES.SUCCESS
            );
            if (!Boolean(contact_id)) {
                dispatch(orderActions.readRecentOrder(account_id));
            }
            onViewOrder(order_id, account_id);
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to create order", STATUS_TYPES.ERROR);
        }
    };

    const onUpdateOrder = async (data) => {
        try {
            await dispatch(
                orderActions.update(
                    account_id,
                    {
                        order_items: formatCartItems(data).items,
                    },
                    null,
                    idBeingUpdated
                )
            );
            Notification.alert(
                "Successfully updated order!",
                STATUS_TYPES.SUCCESS
            );
            if (!Boolean(contact_id)) {
                dispatch(orderActions.readRecentOrder(account_id));
            }
            onViewOrder(idBeingUpdated, account_id);
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to update order", STATUS_TYPES.ERROR);
        }
    };

    const onSaveQuote = async (data) => {
        try {
            const id = await (async () => {
                if (idBeingUpdated) {
                    await dispatch(
                        quoteActions.updateQuote(
                            idBeingUpdated,
                            formatCartItems(data).items
                        )
                    );
                    return idBeingUpdated;
                }
                const contact = await (async () => {
                    if (data.contact_id) {
                        return {
                            email: data.contact_email,
                            contact_id: data.contact_id,
                            phone: data.contact_phone,
                            ship_to: data.contact_name
                        };
                    }
                    const contact = (
                        await dispatch(
                            quoteActions.readQuoteContactInformation(contact_id)
                        )
                    )?.contact;
                    const emails = contact?.email?.data || [];
                    const phones = contact?.phone?.data || [];
                    const locations = contact?.locations || [];
                    const ship_to = `${contact?.first_name} ${contact?.last_name}`;
                    return {
                        email:
                            emails.find((email) => email.main)?.email ||
                            emails[0]?.email ||
                            null,
                        phone:
                            phones.find((phone) => phone.main)?.phone ||
                            phones[0]?.phone ||
                            null,
                        contact_id: Number(contact_id),
                        address: locations.find(location => location.main) || locations[0],
                        ship_to: ship_to
                    };
                })();

                const user = data.from_user_id
                    ? {
                          user_email: data.from_email,
                          user_id: data.from_user_id,
                          user_phone: data.from_phone,
                          profile_info: data.profile_info,
                      }
                    : {
                          user_id,
                          user_email,
                          user_phone,
                          profile_info: true,
                      };

                return dispatch(
                    quoteActions.createQuote(
                        formatCartItems({
                            ...data,
                            contact_info: {
                                location: contact,
                                ship_to: contact.ship_to
                            },
                            account_id,
                            from_information: {
                                email: user.user_email,
                                from_user_id: user.user_id,
                                phone: user.user_phone,
                                profile_info: user.profile_info,
                            },
                            contact_id: Number(contact_id),
                        }),
                        {
                            opportunity_id,
                        }
                    )
                );
            })();
            dispatch(quoteActions.getOpportunityRecentQuote(opportunity_id));
            onEditQuote(id);
        } catch (e) {
            console.log(e);
            Notification.alert(
                `Failed to ${idBeingUpdated ? "update" : "create"} quote`,
                STATUS_TYPES.ERROR
            );
        }
    };

    const {
        handleSubmit,
        formState: { isValid, isDirty },
    } = useFormContext();

    const { isUpdating, isCreating } = useSelector((state) => ({
        isUpdating: state.quote.quoteBeingUpdated || state.order.orderBeingUpdated,
        isCreating:
            state.quote.isCreating ||
            state.quote.quoteContactInfoBeingRead ||
            state.order.isCreating
    }));

    const isSaving = isUpdating || isCreating;
    const shouldHaveOpportunityId = isUpsell ? false : !Boolean(opportunity_id);
    return (
        <>
            <Modal.Content scrolling>
                <Form loading={isSaving} noValidate>
                    <CatalogWizard opportunity_id={opportunity_id} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    loading={isSaving}
                    onClick={handleSubmit(
                        isUpsell
                            ? idBeingUpdated
                                ? onUpdateOrder
                                : onCreateOrder
                            : onSaveQuote
                    )}
                    disabled={
                        shouldHaveOpportunityId ||
                        !isDirty ||
                        !isValid ||
                        isSaving
                    }
                    primary
                >
                    Next
                </Button>
            </Modal.Actions>
        </>
    );
};

export default AddProducts;

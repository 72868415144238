import { NestedDropdown } from "dyl-components";
import React from "react";
import { StringUtils } from "utils";

const OrderStatusField = ({ name, value, onChange }) => {
    const [parent_value, child_value] = (value || "").split("/");
    return (
        <NestedDropdown
            parent_value={parent_value}
            child_value={child_value}
            label="Status"
            allowedToSelectParentOnly
            allowedToSelectParent
            onChange={(e, { parent_value, child_value }) => {
                onChange(e, {
                    name,
                    value: child_value
                        ? `${parent_value}/${child_value}`
                        : parent_value,
                });
            }}
            display_parent
            selection
            nested_options={["draft", "open"].map((status) => ({
                key: status,
                value: status,
                text: StringUtils.capitalize(status),
                options: [],
            }))}
            selectOnBlur={false}
            clearable={false}
            placeholder="Select status"
        />
    );
};

export default OrderStatusField;
